.uploadBody {
  display: flex;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  height: 100% !important;
}

.appWrap {
  height: 100% !important;
}

.imageUploader {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  width: 100%;
  height:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadPictureContainer{
  width: 50% !important;
}

.container {
  // display: flex;
  // width: 100%;
  align-items: center;
}

.buttonWrapper {
  text-align: center;
}

.btn-container {
  text-align: center;
}

.submitImage {
  border: 1px solid grey !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.deleteImage{
  background: #6d6d6d !important;
  color:white !important;
  font-size: 14px !important;
  line-height: 32px !important;
}

.chooseFileButton{

  border-radius: 5px !important;
  padding: 9px 23px !important;
}

.uploadButtonContainer {
  display: block;
  position:absolute;
  bottom: 2em;
  left: calc(50% - 70px);
  text-align: center;
}

.uploadButton{
  padding: 9px 23px;
  background: #3f4257;
  border: .1px solid #828282;
  border-radius: 5px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all .2s ease-in;
  cursor: pointer;
  outline: none;
  width: 10em;
}

.uploadButton:hover{
  background:#545972;
}

.pulseLoader {
  display: flex;
  width: 100%;
  align-items: center;
}

.responseHeaders {
  height: 100%;
  justify-content: center;
  text-align: center;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-items: center;
  align-content: center;
  width: 100%;
}

.responseSubDiv{
  width: 330px;
  padding: 1em;
  background: white;
  height: 125px;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
}

.pstyle{
  width: 100%;
  margin-bottom: 0;
  color:#495057;
}

.fileContainer {
  width: 100%;
  margin: 15px;
  max-width: 800px;
  min-height: 250px;
}

#root {
  background-image: linear-gradient(#14CEFB, #075E9F);
}
